import { GatsbySeo } from 'gatsby-plugin-next-seo'
import LifetimeWarranty from 'src/components/servicos/ServicesPage/GarantiaVitalicia'

function Page() {
  return (
    <>
      <GatsbySeo title="Garantia vitalícia | Decathlon" />
      <LifetimeWarranty />
    </>
  )
}

export default Page

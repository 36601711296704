import SidebarService from 'src/components/ui/SidebarService/SidebarService'

import '../styles.scss'

const LifetimeWarranty = () => {
  return (
    <>
      <section className="section-services life-time-warranty_container w-full">
        <div className="av-container">
          <div className="av-row section-services__flex">
            <div className="av-col-xs-24 av-col-md-7 section-services__column">
              {/* <!-SideBar Garantia Vitalicia-> */}
              <SidebarService />
            </div>

            <div className="av-col-xs-24 av-col-md-17">
              {/* <!- Garantia Vitalicia Content-> */}
              <div className="services-content">
                <div className="section__top align-center py-0 px-2.5 flex flex-col justify-center items-center">
                  <h1 className="text-blue text-center italic text-2xl font-semibold">
                    GARANTIA VITALÍCIA
                  </h1>
                  <div className="button_register-form_container flex items-center justify-center pb-1.5">
                    <a
                      className="button_register-form flex items-center justify-between bg-blue text-white text-xs font-bold py-2 pr-3 pl-7 leading-3 cursor-pointer uppercase hover:bg-shadeOfCerulean whitespace-nowrap"
                      href="/servicos/registre-sua-bicicleta"
                      title="Registre sua bicicleta"
                      target="_blank"
                    >
                      Formulário de registro
                    </a>
                  </div>

                  <span className="text-center text-sm mt-4 text-shadeOfGray">
                    O registro da sua bicicleta é para sua segurança.
                  </span>
                </div>
                <section className="section__middle py-0 px-2.5">
                  <p className="text-left text-sm mt-4">
                    Da concepção à venda das bicicletas, passando pela
                    fabricação, a DECATHLON garante um alto nível de qualidade e
                    segurança. Devido a essa exigência e à organização interna,
                    o nível de qualidade aumenta, as bicicletas duram por um
                    longo tempo e até se tornam parte de um processo de proteção
                    do meio ambiente.
                  </p>

                  <p className="text-left text-sm mt-4">
                    A DECATHLON respeita e segue ativamente a evolução das
                    normas europeias de segurança, que são as mais exigentes do
                    mundo. Devido ao seu próprio laboratório de testes
                    exclusivos baseado em Lille (França), a DECATHLON, através
                    das suas marcas próprias BTWIN, ROCKRIDER, TRIBAN,
                    RIVERSIDE, VAN RYSEL, desenvolve também os seus próprios
                    protocolos para uma melhoria constante de qualidade de seus
                    produtos. Através de sua organização única, suas ferramentas
                    e seu conhecimento, a DECATHLON garante o bem estar
                    cotidiano de seus ciclistas. A garantia permite que não seja
                    necessário comprar uma nova bicicleta em caso de quebra por
                    defeito de fabricação, preservando assim o seu investimento.
                    As bicicletas fabricadas pela DECATHLON são produtos
                    concebidos para proporcionar total satisfação aos seus
                    usuários. É por essa razão que a DECATHLON deseja
                    comprometer-se na qualidade e na segurança de suas
                    bicicletas.
                  </p>

                  <p className="text-left text-sm mt-4">
                    A garantia vitalícia para as bicicletas DECATHLON (ATRAVÉS
                    DAS MARCAS PRÓPRIAS BTWIN, ROCKRIDER, TRIBAN, RIVERSIDE, VAN
                    RYSEL ) é válida a partir do dia 08 de junho de 2013.
                  </p>

                  <p className="text-left text-sm mt-4">
                    Após avaliação, se restar constatado se tratar de defeito de
                    fabricação, cada peça identificada com defeito será reparada
                    ou substituída em uma de nossas oficinas. Se a bicicleta não
                    existir mais, a peça será substituída por um modelo
                    equivalente da gama atual.
                  </p>

                  <h3 className="font-bold mx-0 my-4">
                    Para a garantia vitalícia ser válida, o comprador deverá:
                  </h3>

                  <ol className="pl-10">
                    <li className="list-decimal text-gray">
                      Registrar a compra da bicicleta no&nbsp;
                      <a
                        className="text-shadeOfCyanBlue hover:text-shadeOfBahamaBlue hover:underline"
                        href="/servicos/registre-sua-bicicleta"
                        target="_blank"
                      >
                        formulário de Registro de Bicicletas
                      </a>
                      ;
                    </li>
                    <li className="list-decimal text-gray">
                      Fornecer as seguintes informações: nº da nota fiscal,
                      nome, sobrenome, e-mail, data de compra e número de quadro
                      da bicicleta.
                    </li>
                  </ol>

                  <p className="text-left text-sm mt-4">
                    Sob esta garantia vitalícia, a DECATHLON oferece o reparo ou
                    a substituição das seguintes peças, no prazo de 30 até dias
                    após a confirmação de que se trata de um defeito de
                    fabricação: *quadros e avanços de aço e alumínio (excluindo
                    dobradiças), garfo rígido e guidão (excluindo os com
                    suspensão e de fibra de carbono). A constatação do defeito
                    de fabricação é realizada mediante prévia avaliação da
                    bicicleta em uma loja física, ou no centro de distribuição
                    Decathlon para as compras realizadas pelo site.
                  </p>

                  <p className="text-left text-sm mt-4">
                    A garantia vitalícia não se aplica para os casos de
                    manutenção inadequada ou má utilização da bicicleta,
                    conforme definidas nas instruções de uso do produto.
                  </p>

                  <p className="text-left text-sm mt-4">
                    A DECATHLON reserva-se o direito de verificar se estão
                    reunidas todas as condições para o acionamento da garantia
                    vitalícia, podendo utilizar-se de todos os meios adequados e
                    necessários para a sua verificação.
                  </p>

                  <p className="text-left text-sm mt-4">
                    *Quadros e avanços de aço e alumínio (excluindo dobradiças),
                    garfo rígido e guidão (excluindo os com suspensão e de fibra
                    de carbono) que equipam bicicletas DECATHLON (ATRAVÉS DAS
                    MARCAS PRÓPRIAS BTWIN, ROCKRIDER, RIVERSIDE, VAN RYSEL)
                  </p>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default LifetimeWarranty
